import { WindowsService } from './../core/services/windows.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../routes/user/auth.service';
import { fromEvent, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfirmService } from '../core/utils/confirm/confirm.service';
import { ConfirmResult, ConfirmType } from '../core/constants/confirm-result.enum';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsStoreService } from '../core/services/translations-store.service';
import { NavService } from '../routes/main/nav.service';
import { CommonService } from '../core/services/common.service';
import { LoaderService } from '../core/utils/loader.service';
import { PusherService } from '../core/services/pusher.service';
import moment from 'moment';
import { EventsStoreService } from '../core/services/events-store.service';
import { PreventiveStoreService } from '../core/services/preventive-store.service';
import { Router } from '@angular/router';
import { ToolService } from '../routes/main/tool/tool.service';
import { ToolsStoreService } from '../core/services/tools-store.service';
import { PmCalenderStoreService } from '../core/services/pm-calender-store.service';
import { PartsStoreService } from '../core/services/parts-store.service';
import { FilesStoreService } from '../core/services/files-store.service';
import { UsersStoreService } from '../core/services/users-store.service';
import { CompaniesStoreService } from '../core/services/companies-store.service';
import { ToolPointersStoreService } from '../core/services/tool-pointers-store.service';
import { DrawingToolsStoreService } from '../core/services/drawing-tools-store.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  host: {
    '(document:storage)': 'onStorageChange($event)'
  }
})
export class LayoutComponent implements OnInit {

  // reloadMinutes: number = 15;
  userActivity;
  // userReloadActivity;
  userInactive: Subject<any> = new Subject();
  // userReload: Subject<any> = new Subject();
  openMenu: boolean = true;
  isRTL: boolean = false;
  show: boolean = false;
  pusherMessage: string = "";
  lastUserClick: Date = new Date();
  lastCompanyClick: Date = new Date();

  constructor(public userService: AuthService,
    private translate: TranslateService,
    public commonService: CommonService,
    public translationService: TranslationsStoreService,
    public windowsService: WindowsService,
    private pusherService: PusherService,
    private eventsStoreService: EventsStoreService,
    private preventiveStoreService: PreventiveStoreService,
    public nav: NavService,
    private loader: LoaderService,
    private router: Router,
    private toolService: ToolService,
    private toolsStoreService: ToolsStoreService,
    private pmCalenderStoreService: PmCalenderStoreService,
    private partsStoreService: PartsStoreService,
    private filesStoreService: FilesStoreService,
    private usersStoreService: UsersStoreService,
    private companiesStoreService: CompaniesStoreService,
    private toolPointersStoreService: ToolPointersStoreService,
    private drawingToolsStoreService: DrawingToolsStoreService,
    private confirm: ConfirmService) {
  }

  ngOnInit() {

    this.translationService.rtl$.subscribe(rtl => {
      this.isRTL = rtl;
    })

    this.pusherService.channel.bind('admin-message', (data: { message: string }) => {
      if (data.message) {
        this.pusherMessage = data.message
        this.show = true;
      }
    });

    this.pusherService.channel.bind('change-event', (data: { user: string; company: string; event: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Event changed>>>>>>', data);
        this.setLocaleStorage();
        this.eventsStoreService.syncEvent(data.event).subscribe((event) => {
          if (event.maintenance) {
            this.preventiveStoreService.syncMaintenance(event.maintenance).subscribe(() => {
              this.removeLocaleStorage();
            });
          } else {
            this.removeLocaleStorage();
          }
        });
        if (this.router.url.includes('/main/maintenance/tool/')) {
          this.toolService.setTool(this.toolService.getTool());
        }
      }
    });

    this.pusherService.channel.bind('change-tool', (data: { user: string; company: string; tool: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Tool changed>>>>>>', data);
        this.setLocaleStorage();
        this.toolsStoreService.syncTool(data.tool).subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    this.pusherService.channel.bind('change-tool-bulk', (data: { user: string; company: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Tool bulk changed>>>>>>', data);
        this.setLocaleStorage();
        this.toolsStoreService.load().subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    this.pusherService.channel.bind('change-maintenance', (data: { user: string; company: string; maintenance: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Maintenance changed>>>>>>', data);
        this.setLocaleStorage();
        this.preventiveStoreService.syncMaintenance(data.maintenance).subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    this.pusherService.channel.bind('change-maintenance-bulk', (data: { user: string; company: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Maintenance bulk changed>>>>>>', data);
        this.setLocaleStorage();
        this.preventiveStoreService.load();
      }
    });

    this.pusherService.channel.bind('change-pmc', (data: { user: string; company: string; pmCalendar: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('PM Calendar changed>>>>>>', data);
        this.setLocaleStorage();
        this.pmCalenderStoreService.syncPmCalendar(data.pmCalendar).subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    this.pusherService.channel.bind('change-pmc-bulk', (data: { user: string; company: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('PM Calendar bulk changed>>>>>>', data);
        this.setLocaleStorage();
        this.pmCalenderStoreService.load();
      }
    });

    this.pusherService.channel.bind('change-part', (data: { user: string; company: string; part: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Part changed>>>>>>', data);
        this.setLocaleStorage();
        this.partsStoreService.syncPart(data.part).subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    this.pusherService.channel.bind('change-part-bulk', (data: { user: string; company: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Part bulk changed>>>>>>', data);
        this.setLocaleStorage();
        this.partsStoreService.load().subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    this.pusherService.channel.bind('change-file', (data: { user: string; company: string; file: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('File changed>>>>>>', data);
        this.filesStoreService.syncFile(data.file).subscribe(() => {});
      }
    });

    this.pusherService.channel.bind('change-user', (data: { user: string; company: string; newUser: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('User changed>>>>>>', data);
        this.setLocaleStorage();
        this.usersStoreService.syncUser(data.newUser).subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    this.pusherService.channel.bind('change-user-bulk', (data: { user: string; company: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('User bulk changed>>>>>>', data);
        this.setLocaleStorage();
        this.usersStoreService.load().subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    this.pusherService.channel.bind('change-company', (data: { user: string; company: string; newCompany: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Company changed>>>>>>', data);
        this.setLocaleStorage();
        this.companiesStoreService.syncCompany(data.newCompany).subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });
    this.pusherService.channel.bind('change-tool-pointer', (data: { user: string; company: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Tool pointer changed>>>>>>', data);
        this.setLocaleStorage();
        this.toolPointersStoreService.get().subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });
    this.pusherService.channel.bind('change-drawing-tools', (data: { user: string; company: string; tab?: string | null; }) => {
      if (data.company === this.userService.getCompanyId() && (!data.tab || data.tab !== this.userService.getUniqueTab())) {
        console.log('Drawing tool changed>>>>>>', data);
        this.setLocaleStorage();
        this.drawingToolsStoreService.get().subscribe(() => {
          this.removeLocaleStorage();
        });
      }
    });

    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(event => event.key === "_token")
    ).subscribe(event => {
      if (event.newValue === null) {
        this.notifySignedOut();
      }
    });

    this.setInterval();
    this.userInactive.subscribe(() => {
      this.notifyReload();
    });
    this.updateState();
    // this.setReload();
    // this.userReload.subscribe(() => {
    //   if (!this.nav.downloadFiles$.getValue()) window.location.reload();
    // });

    this.nav.toolDrawing$.subscribe((drawing) => {
      if (drawing) {
        this.openMenu = false;
        if (this.isRTL) {
          $(".section-container").css('margin-right', 0);
          $(".toggle-menu").css('right', 0);
        } else {
          $(".section-container").css('margin-left', 0);
          $(".toggle-menu").css('left', 0);
        }
      }
    })

    const counter = localStorage.getItem('_sign_out_counter');
    if (counter) {
      this.lastCompanyClick = moment(counter).toDate();
    }

    setInterval(() => {
      const time = this.commonService.getInactivityTime("minutes");
      if (this.lastCompanyClick && time > 0 && this.userService.getToken()) {
        localStorage.setItem('_sign_out_counter', this.lastCompanyClick.toString());
        const diff = moment(this.lastCompanyClick).add(time, "minutes").diff(moment(), 'seconds');
        if (diff <= 0 && !this.nav.downloadFiles$.getValue()) {
          if (this.commonService.hasOtpPermission()) {
            this.userService.logout(2);
          } else {
            this.userService.logout(4);
          }
        }
      }
    }, 1000);

    this.userService.user$.subscribe((user) => {
      if (user) {
        this.checkLogout();
      }
    });
  }

  setLocaleStorage() {
    this.commonService.setLocalStorage(
      `_at_pusher`,
      true
    );
  }

  removeLocaleStorage() {
    if (!this.router.url.includes('/main/maintenance/tool/') || !this.router.url.includes('/main/calendar')) {
      this.commonService.removeLocalStorage(
        `_at_pusher`
      );
    } else {
      setTimeout(() => {
        this.commonService.removeLocalStorage(
          `_at_pusher`
        );
      }, 10000);
    }
  }

  notifySignedOut() {
    this.confirm.show(this.translate.instant('confirm.GENERAL.SIGNED_OFF'), { type: ConfirmType.TIMER, confirmText: "Dismiss" }).subscribe((result: ConfirmResult) => {
      window.location.reload();
    })
  }

  notifyReload() {
    if (!this.nav.downloadFiles$.getValue()) {
      this.loader.add();
      this.userService.logout();
    }
  }

  setInterval() {
    const time = this.commonService.getInactivityTime();
    if (time > 0) {
      clearInterval(this.userActivity);
      this.userActivity = setInterval(() => this.userInactive.next(undefined), time);
    }
  }

  // setReload() {
  //   const time = this.commonService.getInactivityTime();
  //   const rTime = this.reloadMinutes * 60000
  //   if (rTime < time || time === 0) {
  //     clearInterval(this.userReloadActivity);
  //     this.userReloadActivity = setInterval(() => {
  //       this.userReload.next(undefined)
  //     }, rTime);
  //   }
  // }

  updateState() {
    setInterval(() => this.userService.updateStateUser(), 10000);
  }

  onOpenMenuClick(event: boolean) {
    this.openMenu = event;
    if (event) {
      if (this.isRTL) {
        $(".section-container").css('margin-right', 220);
        $(".toggle-menu").css('right', 189);
      } else {
        $(".section-container").css('margin-left', 220);
        $(".toggle-menu").css('left', 189);
      }
      $(".section-container .filter-width").css('width', `calc(100% - 220px)`);
      $(".section-container .maintenance-block").css('width', `calc(100% - 220px)`);
    } else {
      if (this.isRTL) {
        $(".section-container").css('margin-right', 0);
        $(".toggle-menu").css('right', 0);
      } else {
        $(".section-container").css('margin-left', 0);
        $(".toggle-menu").css('left', 0);
      }
    }
  }

  checkLogout() {
    const hasOtpPermission = this.commonService.hasOtpPermission();
    const token = localStorage.getItem('_token');
    const getTabOpen = localStorage.getItem('_tab_open');
    const getTabOpenNumber = getTabOpen ? Number(getTabOpen) : 0;
    if (hasOtpPermission) {
      localStorage.setItem('_navigation_type', `${window.performance.navigation.type}--${new Date().toString()}}`);
      localStorage.setItem('_tab_o_type', `${getTabOpenNumber}--${new Date().toString()}}`);
      if (window.performance.navigation.type != 1 && token && !getTabOpenNumber) {
        this.userService.logout(2);
      } else if (!token) {
        localStorage.removeItem('_tab_open');
        location.reload();
      }
    }
    localStorage.setItem('_tab_open', (getTabOpenNumber + 1).toString());
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event) {
    const getTabOpen = localStorage.getItem('_tab_open');
    const getTabOpenNumber = getTabOpen ? Number(getTabOpen) : 0;
    if (getTabOpenNumber) {
      localStorage.setItem('_tab_open', (getTabOpenNumber - 1).toString());
    }
    if (this.userService.getToken()) {
      const hasOtpPermission = this.commonService.hasOtpPermission();
      localStorage.setItem('_navigation_type_beforeunload', `${event.currentTarget.performance.navigation.type}--${new Date().toString()}}`);
      if (hasOtpPermission && event.currentTarget.performance.navigation.type === 2) {
        this.userService.logout(2);
      } else {
        this.userService.updateLogout(3).subscribe()
      }
    }
  }

  // @HostListener('document:mousemove') refreshUserState() {
  //   this.setInterval();
  // }

  @HostListener('document:touchstart')
  mobileReloadUserState() {
    if (this.windowsService.isMobile()) {
      // this.setReload();
      this.setInterval();
      const date = new Date().toString();
      this.lastCompanyClick = new Date(date);
      this.lastUserClick = new Date(date);
      // this.addOTPClick(true);
    }
  }

  @HostListener('document:click')
  reloadUserState() {
    if (!this.windowsService.isMobile()) {
      // this.setReload();
      this.setInterval();
      const date = new Date().toString();
      this.lastCompanyClick = new Date(date);
      this.lastUserClick = new Date(date);
      // this.addOTPClick(false);
    }
  }

  // addOTPClick(isMobile: boolean) {
  //   if (this.commonService.hasOtpPermission()) {
  //     this.userService.addOTPClick({
  //       isMobile: isMobile,
  //       browser: this.windowsService.browser(),
  //     }).subscribe();
  //   }
  // }

  handleReload = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    window.location.reload();
  }

}
