import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../routes/user/auth.service';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowsService } from '../services/windows.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  baseUrl = "https://anymaint-backend-dev.herokuapp.com";
  constructor(private auth: AuthService, private router: Router, private injector: Injector, private windowsService: WindowsService) {
    const host = this.windowsService.getHostname();
    if (process.env.NG_APP_API_URL) {
      this.baseUrl = process.env.NG_APP_API_URL;
    } else {
      switch (host) {
        case "cmms.anymaint.com":
          this.baseUrl = "https://anymaint-backend-develop.anymaint.com"
          break;
        case "preprod.anymaint.com":
          this.baseUrl = "https://anymaint-backend-preprod.anymaint.com"
          break;
        case "app.anymaint.com":
          this.baseUrl = "https://anymaint-backend.herokuapp.com"
          break;
        case "localhost":
          this.baseUrl = "http://localhost:3000"
          break;
        default:
          break;
      }
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('/api/') && !request.url.includes('saml/token/')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`,
          Company: `${this.auth.getCompany()}`,
          Tab: this.auth.getUniqueTab()
        }
      });
    } else {
      request = request.clone({
        url: `${this.baseUrl}${request.url}`,
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`,
          Company: `${this.auth.getCompany()}`,
          Tab: this.auth.getUniqueTab()
        }
      });
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 && !request.url.match(/\/api\/login$/) && !request.url.match(/\/api\/renew_token$/)) {
          this.auth.logout();
          let route = this.injector.get(ActivatedRoute);
          while (route.firstChild) {
            route = route.firstChild;
          }
          route.data.subscribe(data => {
            if (data.protected) {
              this.router.navigate(['/login']);
            }
          });
        }
        return throwError(err);
      })
    );
  }
}
