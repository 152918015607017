import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonService } from '../../../../../core/services/common.service';
import { ProcedureStoreService } from '../../../../../core/services/procedure-store.service';
import { TranslationsStoreService } from '../../../../../core/services/translations-store.service';
import { WindowsService } from '../../../../../core/services/windows.service';
import { Tool } from '../../../../../core/interfaces/tool';

@Component({
  selector: 'app-copy-paste-procedure',
  templateUrl: './copy-paste-procedure.component.pug',
  styleUrls: ['./copy-paste-procedure.component.scss']
})
export class CopyPasteProcedureComponent implements OnInit {

  isPM:boolean = false;
  tool:Tool = null;
  eventName:string = null;
  taskText:string = null;
  columnDefs: any = []
  form: UntypedFormGroup;
  isNeedToDisplay: boolean = false;
  tableName:string = "";
  lightScrollbarOptions = this.commonService.getSlowLightScrollbarOptions();

  @ViewChild('documentEditForm') documentEditForm: FormGroupDirective;

  constructor(
    private translate: TranslateService,
    private procedureStoreService: ProcedureStoreService,
    public fb: UntypedFormBuilder,
    public translationService: TranslationsStoreService,
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    public windowsService: WindowsService,
    private toaster: ToasterService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: this.fb.control('', Validators.required),
      tasks: this.fb.control('', Validators.required),
    });
    this.form.get('name').setValue(`${this.tool ? this.tool.name : ''}${this.eventName ? `_${this.eventName}` : ''}`);
    if (this.taskText) {
      this.form.get('tasks').setValue(this.taskText);
    }
  }

  submit(): Observable<any> {
    if (this.form.invalid) return of(false);
    return this.signatureUpdate();
  }

  signatureUpdate = () => {
    const values = Object.assign({}, this.form.value);
    return this.procedureStoreService.copyPasteProcedure({...values, isPM: this.isPM}).pipe(
      tap(() => {
        this.bsModalRef.hide();
        this.toaster.pop("success", this.translate.instant('toasters.GENERAL.OK_SAVE'));
      })
    )
  }

  submitForm = ($event) => {
    this.documentEditForm.ngSubmit.emit();
  }

}
