import { AUTO_COMPLETE_TOOl, EVENT_STATUS, FORMULA_MEASUREMENT_TYPE, FUNCTION_TYPE } from './../constants/enums/enums';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { differenceInDays } from 'date-fns';
import { isEmpty, isString } from 'lodash';
import moment, { isMoment, Moment } from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../routes/user/auth.service';
import { weekDays } from '../constants/week-days';
import { Company } from '../interfaces/company';
import { ToolEvent } from '../interfaces/tool-event';
import { decryptField } from '../utils/general';
import { Formula } from '../interfaces/measurement';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  company: any;
  dateMonthFormat: string = "MM-YYYY";
  dateFormat: string = "DD-MM-YYYY";
  hoursFormat: string = "HH:mm";
  dateTimeFormat: string = "DD-MM-YYYY HH:mm";
  globalDateFormat: string = "YYYY-MM-DD";
  globalDateTimeFormat: string = "YYYY-MM-DD HH:mm";
  timeStep: number = 30;
  public company$: BehaviorSubject<Company> = new BehaviorSubject(null);

  constructor(
    private auth: AuthService,
    private toaster: ToasterService,
    private translate: TranslateService,
  ) {
    this.auth.user$.subscribe(user => {
      if (user && user._id) {
        this.company = user.company;
        this.company$.next(this.company);
      }
    })
  }

  getFirstDayOfWeek = () => {
    return this.company.firstDayOfWeek || 0;
  }

  displayEmailError = (err, message: string) => {
    if(err && err.status === 402 && err.error) {
      this.toaster.pop('error', this.translate.instant(`general.${err.error}`));
    } else {
      this.toaster.pop('error', message);
    }
  }

  getTableRecord = (company: Company, section: string) => {
    if (company && company.tablesPresentation && company.tablesPresentation.length > 0) {
      const e = company.tablesPresentation.find(t => t.section === section);
      if (e) {
        return {
          ...e,
          records: e.records === "all" ? 0 : Number(e.records)
        }
      }
    }
    return {
      section: section,
      records: 0,
      column: '',
      sort: '',
    }
  }

  isActiveEvent = (status) => {
    return status === EVENT_STATUS.ACTIVE;
  }

  isQaEvent = (status) => {
    return status === EVENT_STATUS.WAITING_FOR_QA && this.auth.isQa();
  }

  isSafetyEvent = (status) => {
    return status === EVENT_STATUS.WAITING_FOR_SAFETY && this.auth.isSafety();
  }

  getAutoCompleteTool = () => {
    return this.company && this.company.defaultAutoCompleteTool ? this.company.defaultAutoCompleteTool : AUTO_COMPLETE_TOOl.JS;
  }

  setLocalStorage = (key, value) => {
    return localStorage.setItem(key, value);
  }

  getLocalStorage = (key) => {
    return localStorage.getItem(key);
  }

  removeLocalStorage = (key) => {
    return localStorage.removeItem(key);
  }

  updateCompany = (company) => {
    this.company = company;
    this.company$.next(this.company);
  }

  getCompany = () => {
    return this.company;
  }

  getDaysOff = () => {
    return this.company.daysOff || [];
  }

  getDefaultLanguage = () => {
    return this.company.defaultLanguage;
  }

  getWorkerPassword = () => {
    return this.company.defaultWorkerPassword ? decryptField(this.company.defaultWorkerPassword) : "a";
  }

  getColumnValue = (ev: any, acc: any, curr: any) => {
    let value = "";
    const event = { ...ev }
    if (curr.valueFormatter) value = curr.valueFormatter({ data: event })
    else if (curr.valueGetter) value = curr.valueGetter({ data: event })
    else if (curr.field.includes('.')) {
      const props = curr.field.split('.');
      value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : {}, event);
    }
    else value = event[curr.field];
    return { ...acc, [curr.headerName]: value }
  }

  checkTwoDates(sDate, fDate) {
    sDate = isMoment(sDate) ? sDate : moment(new Date(sDate));
    fDate = isMoment(fDate) ? fDate : moment(new Date(fDate));
    return moment(fDate).diff(sDate, 'minutes')
  }

  compareTwoDates(sDate, fDate) {
    sDate = isMoment(sDate) ? sDate : moment(new Date(sDate));
    fDate = isMoment(fDate) ? fDate : moment(new Date(fDate));
    return sDate < fDate;
  }

  isSameTwoDates(sDate, fDate) {
    return moment(this.convertDateForDateObject(sDate), "YYYY-MM-DD").isSame(moment(this.convertDateForDateObject(fDate), "YYYY-MM-DD"));
  }

  getEventImageName(event) {
    return event ? ((event.worker.firstName ? event.worker.firstName.charAt(0) : '') + (event.worker.lastName ? event.worker.lastName.charAt(0) : '')).toUpperCase() : '';
  }

  getTaskTransactionImageName(event) {
    return event ? ((event.user.firstName ? event.user.firstName.charAt(0) : '') + (event.user.lastName ? event.user.lastName.charAt(0) : '')).toUpperCase() : '';
  }

  chunkArray = (array: [], chunk) => {
    var chunks = [], i = 0, n = array.length;
    while (i < n) {
      chunks.push(array.slice(i, i += chunk));
    }
    return chunks;
  }

  getDateTimeFormat(company: any = this.company) {
    const date = company.shortDateFormat ? company.shortDateFormat : this.dateFormat;
    const hours = this.getTimeFormat(company);
    return date + ' ' + hours;
  }

  getDateFormat(company: any = this.company) {
    return company.shortDateFormat ? company.shortDateFormat : this.dateFormat;
  }

  getTimeStep(company: any = this.company) {
    return company.timeStep ? company.timeStep : this.timeStep;
  }

  getDateMonthFormat(company: any = this.company) {
    if (company.shortDateFormat) {
      return company.shortDateFormat.replace("DD/", "").replace("DD-", "");
    }
    return this.dateMonthFormat;
  }

  getDateFormatForPicker(company: any = this.company) {
    let date = company.shortDateFormat ? company.shortDateFormat : this.dateFormat;
    date = date.replace('DD', 'dd');
    date = date.replace('YYYY', 'yyyy');
    return date;
  }

  getTimeFormat(company: any = this.company) {
    let time = this.hoursFormat;
    if (company.timeFormat) {
      if (company.timeFormat == '12') time = "h:mm A";
    }
    return time;
  }

  getTimeFormatForPicker(company: any = this.company) {
    let time = 'Hm';
    if (company.timeFormat) {
      if (company.timeFormat == '12') time = "HmA";
    }
    return time;
  }

  getTimeFormatForPickerOnValue(format) {
    let time = 'Hm';
    if (format == '12') time = "HmA";
    return time;
  }

  getTimezone = () => {
    return this.company.timezone ? this.company.timezone : 'UTC';
  }

  getTimezoneOffset = () => {
    const offset = moment().tz(this.getTimezone()).utcOffset();
    return (offset > 0 ? "+" : "-") + moment().startOf('day').add(offset, 'minutes').format('HH:mm');
  }

  getDateTime = (date: any) => {
    return moment(this.convertDateForDateObject(date)).format(this.getDateTimeFormat());
  }

  getDate = (date: any) => {
    return moment(this.convertDateForDateObject(date)).format(this.getDateFormat());
  }

  getDateWithoutTz = (date: any) => {
    return moment(date).format(this.getDateFormat());
  }

  getConvertTableDate = (date: any) => {
    return moment(this.convertDateForDateObject(date)).format("YYYY-MM-DD");
  }

  getConvertTableDateTime = (date: any) => {
    return moment(this.convertDateForDateObject(date)).format("YYYY-MM-DD HH:mm:ss");
  }

  getDateMonth = (date: any) => {
    return moment(this.convertDateForDateObject(date)).format(this.getDateMonthFormat());
  }

  getDateWeek = (date: any) => {
    return moment(this.convertDateForDateObject(date)).format("ww-YYYY");
  }

  getTime = (date: any) => {
    return moment(this.convertDateForDateObject(date)).format(this.getTimeFormat());
  }

  getComparableDate = (date: any) => {
    return moment(this.convertDateForDateObject(date));
  }

  dateTimeBetweenTwoDates = (start, end, unit) => {
    start = isMoment(start) ? start : moment(new Date(start));
    end = isMoment(end) ? end : moment(new Date(end));
    const workingDays = this.workingDaysBetweenTwoDates(start, end);
    const dayMinutes = this.workingMinutesOfDay();
    let startOffMinutes = this.getStartTimeCalculation(start);
    const endOffMinutes = this.getStartTimeCalculation(end);
    if (startOffMinutes > dayMinutes) startOffMinutes = 0;
    let finalMinutes = 0;
    if (workingDays == 1 || workingDays == 0) {
      if (moment(end).isSame(start, 'day')) {
        finalMinutes = moment.duration(moment(end).diff(start)).asMinutes();
      } else {
        finalMinutes = this.getEndTimeCalculation(start) + endOffMinutes;
      }
    } else {
      const startEndTimeCalc = this.getEndTimeCalculation(start);
      let totalMinus = 2;
      let minutesToPlus = startEndTimeCalc + endOffMinutes;
      if (this.company && this.company.daysOff.includes(start.format('dddd'))) {
        totalMinus -= 1;
        minutesToPlus -= startEndTimeCalc;
      }
      if (this.company && this.company.daysOff.includes(end.format('dddd'))) {
        totalMinus -= 1;
        minutesToPlus -= endOffMinutes;
      }
      finalMinutes = ((workingDays - totalMinus) * dayMinutes) + minutesToPlus;
    }
    if (unit == 'minutes') {
      return finalMinutes;
    } else if (unit == 'hours') {
      return this.convertMinutesToHours(finalMinutes);
    } else {
      return workingDays;
    }
  }

  getCompanyStartHour = () => {
    return this.company.dailyWorkHours.start;
  }

  getStartTimeCalculation = (start) => {
    const date = moment(moment(start).format('YYYY-MM-DD') + ' ' + (this.company.dailyWorkHours.start))
    const eDate = moment(moment(start).tz(this.getTimezone()).format('YYYY-MM-DD HH:mm:ss'));
    const duration = moment.duration(moment(eDate).diff(date));
    const minutes = duration.asMinutes()
    return minutes < 0 ? 0 : minutes;
  }

  getDailyWorkHours = () => {
    return this.company.dailyWorkHours.end === "00:00" ? "23:59:59" : this.company.dailyWorkHours.end;
  }

  getCompanyStartAndEndTime = () => {
    return {
      start: this.getCompanyStartHour(),
      end: this.getDailyWorkHours()
    }
  }

  getEndTimeCalculation = (end) => {
    const date = moment(moment(end).format('YYYY-MM-DD') + ' ' + this.getDailyWorkHours())
    const eDate = moment(moment(end).tz(this.getTimezone()).format('YYYY-MM-DD HH:mm:ss'));
    const duration = moment.duration(moment(date).diff(eDate));
    const minutes = duration.asMinutes()
    return minutes < 0 ? 0 : minutes;
  }

  isDST = () => {
    const date = moment().tz(this.getTimezone())
    const day = Number(date.format('D'));
    const month = Number(date.format('M'));
    const dow = date.day();
    if (month < 3 || month > 11) { return false; }
    if (month > 3 && month < 11) { return true; }
    let previousSunday = day - dow;
    if (month == 3) { return previousSunday >= 8; }
    return previousSunday <= 0;
  }

  isNeedToAdjustTime = () => {
    if (this.company.dayLight) {
      return this.isDST();
    } else {
      return this.company.dayLightManual == 'winter';
    }
  }

  getFormattedDate = (date: any, format = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(date).format(format).replace(/-/g, '/');
  }

  getOriginalDateAfterDatePicker = (date: any) => {
    let soffset = moment(date).tz(this.getTimezone()).utcOffset();
    const csOffset = moment(date).utcOffset();
    return new Date(this.getFormattedDate(moment(date).tz('UTC').utcOffset((csOffset - soffset) + csOffset)));
  }

  getTzOffset = () => {
    return moment().tz(this.getTimezone()).utcOffset();
  }

  convertDateForDateObject = (date: any = '', format = 'YYYY-MM-DD HH:mm:ss') => {
    if (date) {
      return moment(date).tz(this.getTimezone()).format(format).replace(/-/g, '/')
    } else {
      return moment().tz(this.getTimezone()).format(format).replace(/-/g, '/')
    }
  }

  checkDateIsInFuture = (date: Date) => {
    return date.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
  }

  workingDays = () => {
    return Object.keys(weekDays).reduce((acc, key) => {
      if (this.company.daysOff.indexOf(weekDays[key]['value']) === -1) {
        acc.push(Number(key));
      }
      return acc;
    }, []);
  }

  workingDaysBetweenTwoDates = (startDate, finishDate) => {
    startDate = moment(startDate).startOf('day');
    finishDate = moment(finishDate).endOf('day');
    let workingDays = 0;
    const days = finishDate.diff(startDate, 'days') + 1;
    if (this.company.daysOff.length > 0) {
      const weekOffDays = Object.keys(weekDays).reduce((acc, key) => {
        if (this.company.daysOff.indexOf(weekDays[key]['value']) > -1) {
          acc.push(Number(key));
        }
        return acc;
      }, []);
      let firstDay = startDate.day();
      let totalOffDay = 0;
      for (let i = 0; i < days; i++) {
        if (weekOffDays.indexOf(firstDay) > -1) {
          totalOffDay++;
        }
        firstDay++;
        if (firstDay == 7) {
          firstDay = 0;
        }
      }
      workingDays = days - totalOffDay;
    } else {
      workingDays = days
    }
    return workingDays;
  }

  workingMinutesOfDay = (): number => {
    const startTime = moment(this.company.dailyWorkHours.start, 'HH:mm:ss');
    const endTime = moment(this.getDailyWorkHours(), 'HH:mm:ss');
    return moment.duration(endTime.diff(startTime)).asMinutes();
  }

  workingHoursOfDay = (start: string, end: string): number => {
    const startTime = moment(start, 'HH:mm:ss');
    const endTime = moment(end === "00:00" ? "23:59:59" : end, 'HH:mm:ss')
    const time = moment.duration(endTime.diff(startTime)).asHours();
    return time > 23.8 ? 24 : Number(time.toFixed(2));
  }

  workingHoursBetweenTwoDates = (startDate, finishDate) => {
    return this.workingDaysBetweenTwoDates(startDate, finishDate) * this.workingMinutesOfDay();
  }

  isSameDate = (first, second) => {
    var x = moment(first, "YYYY-MM-DD");
    return x.isSame(moment(second, "YYYY-MM-DD"), 'day');
  }

  isJsonString = (str: string): boolean => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getUploadErrorMessage = (type: string, errors: [], falseLine: any) => {
    let messages: any;
    if (type == 'pm') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: 'ID column isn’t filled properly. Please leave it empty for new PMs or as-is for the exists',
        3: `Maintenance type ID in rows: ${falseLine[3]} has problems. Please download the template again, and fill it according to the instructions`,
        4: `Tool ID in rows: ${falseLine[4]} has problems. Please download the template again, and fill it according to the instructions`,
        5: `Last maintenance in rows: ${falseLine[5]} has problems. Please try with valid values`,
        6: `Next maintenance in rows: ${falseLine[6]} has problems. Please try with valid values`,
        7: `Gray must be less than Red line. Please check line number ${falseLine[7]} in your sheet.`,
      };
    } else if (type == 'tools') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: `ID column isn’t filled properly. ID in rows: ${falseLine[2]} has problems. Please leave it empty for new tools or as-is for the exists`,
        3: `Tool name in rows: ${falseLine[3]} has problems. Should contain tool name, that will be present for users`,
        4: `Group ID in rows: ${falseLine[4]} has problems. Group ID does not exist in the table. Please add rows with all Groups ID you have used.`,
        5: `Tool ID in rows: ${falseLine[5]} has problems. Tool ID does not exist in the table. Please add rows with all Tools ID you have used.`,
      };
    } else if (type == 'users') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: 'ID column isn’t filled properly. Please leave it empty for new users or as-is for the exists',
        3: 'Username column isn’t filled properly. Should contain short and unique username. John, JohnC, Jonny is OK. If there is more than one users with name “John” in the system – use JohnC, John1 etc',
        4: 'Password column isn’t filled properly. Leave it empty for exists users, fill for the new ones. Password must contain: 1 number, 1 uppercase, 1 lowercase, 1 special character (@#$%^&+*!=), 6 characters',
        5: 'Email column isn’t filled properly and must contain valid email address (with “@”). If the user hasn’t one, the email of system admin could be used.',
        6: 'Permissions column isn’t filled properly. should contain only values listed in first system admin authorization field (Failure Reporting, Define Tool as Operational, Report Maintenance, Create Maintenance, Inventory, Dispatch Orders, Full Administrative Rights, Billing). If user does not need the authorization, just delete it, with a separated comma: (Define Tool as Operational, Report Maintenance, Create Maintenance,)-> (Define Tool as Operational, Create Maintenance,). You may leave it empty.',
        7: 'Permissions column isn’t filled properly. The table must contain at least one “Full Administrative Rights” and one “Billing” user',
      };
    } else if (type == 'workers') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: 'ID column isn’t filled properly. Please leave it empty for new users or as-is for the exists',
        3: 'Username column isn’t filled properly. Should contain short and unique username. John, JohnC, Jonny is OK. If there is more than one users with name “John” in the system – use JohnC, John1 etc',
        4: 'Password column isn’t filled properly. Leave it empty for exists users, fill for the new ones. Password must contain: 1 number or 1 uppercase or 1 lowercase or 1 special character (@#$%^&+*!=)',
        5: 'Email column isn’t filled properly and must contain valid email address (with “@”). If the user hasn’t one, the email of system admin could be used.',
      };
    } else if (type == 'events') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: 'ID column isn’t filled properly. Please leave it empty for new users or as-is for the exists',
        3: 'Type column isn’t filled properly. Should contain machine status or PM kinds, that will be present for tool',
        4: 'Start date and finish date column isn’t filled properly. Should contains valid value.',
        5: 'Father event ID column isn’t filled properly. Please fill correct father event ID',
      };
    } else if (type == 'tasks') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: 'ID column isn’t filled properly. Please leave it empty for new task or as-is for the exists',
        3: 'Name column isn’t filled properly. Should contain valid name.',
      }
    } else if (type == 'custom-table') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: 'ID column isn’t filled properly. Please leave it empty for new task or as-is for the exists',
      }
    } else if (type == 'drawing') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: 'ID column isn’t filled properly. Please fill as-is for the exists',
      }
    } else if (type == 'quantity-query') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
      }
    } else if (type == 'pm-calendar') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: 'ID column isn’t filled properly.  Please leave it empty for new PM calendar or as-is for the exists',
        3: `Tool ID in rows: ${falseLine[3]} has problems. Please insert correct Tool IDs from the tools table`,
        4: `PM ID in rows: ${falseLine[4]} has problems. Please insert correct PM IDs from the PM table.`,
        5: `PM due date in rows: ${falseLine[5]} has problems. Please insert correct date formats.`,
        6: `Actual date in rows: ${falseLine[6]} has problems. Please insert correct date formats or Event ID from the History table in the ‘Event ID’ column`,
        7: `Event ID: ${falseLine[7]} has problems. Please insert correct Event ID from the ‘ID’ column in the History table`,
        8: `Worker name: ${falseLine[8]} has problematic content. Replace problematic text`,
        9: `Status: ${falseLine[9]} has problems. Please insert correct Status of done events`,
      }
    } else if (type == 'ai-helper') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: `ID column isn’t filled properly. ID in rows: ${falseLine[2]} has problems. Please leave it empty for new ai helper or as-is for the exists`,
        3: `Item ID column isn’t filled properly. Item ID in rows: ${falseLine[3]} has problems. Please fill it with the correct item ID`,
      }
    } else if (type == 'translation-correction') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: `ID column isn’t filled properly. ID in rows: ${falseLine[1]} has problems. Please leave it empty for new translation correction or as-is for the exists`,
      }
    } else if (type == 'keywords') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: `ID column isn’t filled properly. ID in rows: ${falseLine[1]} has problems. Please leave it empty for new keywords or as-is for the exists`,
        3: `Name column isn’t filled properly. Name in rows: ${falseLine[2]} has duplication. Please remove duplicated name and try again`,
      }
    } else if (type == 'inventory') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: `ID column isn’t filled properly. ID in rows: ${falseLine[2]} has problems. Please leave it empty for new parts or as-is for the exists`,
      }
    } else if (type == 'tool-transaction') {
      messages = {
        1: 'Columns order is different from the template. Please download the template again, and fill it according to the instructions',
        2: `Tool ID column isn’t filled properly. Tool ID in rows: ${falseLine[2]} has problems. Please fill valid Tool ID from tools`,
        3: `Part ID column isn’t filled properly. Part ID in rows: ${falseLine[3]} has problems. Please fill valid Part ID from parts`,
      }
    };
    const msg = errors.map((data, key) => {
      return (key + 1) + '. ' + messages[data];
    }).join(' <br/> ');

    const toast: Toast = {
      type: 'error',
      title: '',
      body: msg,
      bodyOutputType: BodyOutputType.TrustedHtml,
      timeout: 0
    };
    return toast;
  }

  getTokenString = () => {
    return `?access_token=${this.auth.getToken()}&company=${this.auth.getCompany()}`;
  }

  isFileTypeImage = (filename: string) => {
    const extension = filename.split('.').pop();
    return ['jpeg', 'jpg', 'png'].includes(extension.toLowerCase());
  }

  checkSLATime = (date, startDate) => {
    const minutes = this.getSLATime('minutes');
    const time = this.dateTimeBetweenTwoDates(moment(startDate).add(minutes, 'minutes').tz(this.getTimezone()), moment(date).tz(this.getTimezone()), 'minutes')
    return (time > 0);
  }

  checkPmCalenderTime = (actualDate, dueDate) => {
    const date = new Date(dueDate)
    date.setHours(0, 0, 0, 0)
    actualDate = new Date(actualDate)
    actualDate.setHours(0, 0, 0, 0)
    return differenceInDays(actualDate, date);
    // return this.dateTimeBetweenTwoDates(moment(actualDate).tz(this.getTimezone()), moment(dueDate).tz(this.getTimezone()), 'days')
  }

  getTimesInMinutesFromNow(date, compareDate = ''): number {
    const lastDate = compareDate ? moment(compareDate).tz(this.getTimezone()) : moment().tz(this.getTimezone())
    return Math.floor(this.dateTimeBetweenTwoDates(moment(date).tz(this.getTimezone()), lastDate, 'minutes'));
  }

  getTimesInHoursFromNow(date, compareDate = ''): number {
    const lastDate = compareDate ? moment(compareDate).tz(this.getTimezone()) : moment().tz(this.getTimezone())
    return Math.floor(this.dateTimeBetweenTwoDates(moment(date).tz(this.getTimezone()), lastDate, 'hours'));
  }

  getTimeInString(min: number): string {
    const hours = Math.floor(min / 60);
    const minutes = min % 60;
    return (hours > 0 ? hours + ' hours ' : '') + (minutes > 0 ? minutes + ' minutes' : '');
  }

  getTimeInStringSeconds(d: number): string {
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);

    const hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  getTimeInStringSecondsInShort(d: number): string {
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);

    const hDisplay = h > 0 ? `${h} ${this.translate.instant("general.H")} ` : "";
    const mDisplay = m > 0 ? `${m} ${this.translate.instant("general.M")} ` : "";
    const sDisplay = s > 0 ? `${s} ${this.translate.instant("general.S")}` : "";
    return hDisplay + mDisplay + sDisplay;
  }

  getTimeShorterInString(min: number): string {
    const d = Math.floor(min / 60 / 24);
    const h = Math.floor(min / 60 % 24);
    const m = min % 60;

    const dDisplay = d > 0 ? d + (d == 1 ? ` ${this.translate.instant("general.DAY")} ` : ` ${this.translate.instant("general.DAYS")} `) : "";
    const hDisplay = h > 0 ? h + (h == 1 ? ` ${this.translate.instant("general.HOUR")} ` : ` ${this.translate.instant("general.HOURS")} `) : "";
    const mDisplay = m > 0 ? m + (m == 1 ? ` ${this.translate.instant("general.MINUTE")}` : ` ${this.translate.instant("general.MINUTES")}`) : "";

    return dDisplay + hDisplay + mDisplay;
  }

  getTimeDiff = (date, compareDate): string => {
    const time = this.getTimesInMinutesFromNow(date, compareDate);
    return this.getTimeInString(time);
  }

  getSLATime = (type = "hours"): number => {
    const m = moment(moment().format('YYYY-MM-DD') + ' ' + (this.company.slaTime ? this.company.slaTime : '02:00'));
    if (type == "hours") {
      return Number(m.hour() + (m.minute() / 60));
    } else {
      return (m.hour() * 60) + m.minute();
    }
  }

  convertMinutesToHours = (min: number): number => {
    const hours = Math.floor(min / 60);
    const minutes = Number((min % 60 / 60).toFixed(2));
    return hours + minutes;
  }

  isSignatureRequired = () => {
    return this.company.freemiumPermissions.signature || false;
  }

  hasWhatsAppPermission = () => {
    return this.company.freemiumPermissions.whatsApp || false;
  }

  hasSmartDropDownPermission = () => {
    return this.company.freemiumPermissions.smartDropDown || false;
  }

  hasSlaPermission = () => {
    return this.company.freemiumPermissions.sla || false;
  }

  hasAuditTrailPermission = () => {
    return this.company.freemiumPermissions.auditTrail || false;
  }

  hasBigTeamPermission = () => {
    return this.company.freemiumPermissions.bigTeam || false;
  }

  hasIpAddressPermission = () => {
    return this.company.freemiumPermissions.limitedIP || false;
  }

  hasOtpPermission = () => {
    return this.company.freemiumPermissions.otp || false;
  }

  hasProceduresAndTasksPermission = () => {
    return this.company.freemiumPermissions.proceduresAndTasks || false;
  }

  hasOpenEventFromTaskPermission = () => {
    return this.company.freemiumPermissions.openEventFromTask || false;
  }

  hasTranslateAlwaysTogglePermission = () => {
    return this.company.freemiumPermissions.translateAlwaysToggle || false;
  }

  hasSubSystems = () => {
    return this.company.freemiumPermissions.subSystems || false;
  }

  hasInventoryPermission = () => {
    return this.company.freemiumPermissions.inventory || false;
  }

  hasSmartSchedulePermission = () => {
    return this.company.freemiumPermissions.smartSchedule || false;
  }

  hasMeasurementsPermission = () => {
    return this.company.freemiumPermissions.measurements || false;
  }

  hasApiPermission = () => {
    return this.company.freemiumPermissions.api || false;
  }

  hasAutomaticAssistantPermission = () => {
    return this.company.freemiumPermissions.automaticAssistant || false;
  }

  hasModeratorPermission = () => {
    return this.company.freemiumPermissions.moderator || false;
  }

  hasAllowSignAllTasksPermission = () => {
    return this.company.freemiumPermissions.allowSignAllTasks || false;
  }

  hasHideChecklistPreviewPermission = () => {
    return this.company.freemiumPermissions.hideChecklistPreview || false;
  }

  hasScheduledReportsPermission = () => {
    return this.company.freemiumPermissions.scheduledReports || false;
  }

  hasHideTasksFromFmPermission = () => {
    return this.company.freemiumPermissions.hideTasksFromFm || false;
  }

  hasSaveEventAsOpenPermission = () => {
    return this.company.freemiumPermissions.saveEventAsOpen || false;
  }

  hasCalendarPermission = () => {
    return this.company.freemiumPermissions.calendar || false;
  }

  hasAllowHideDataInGraphs = () => {
    return this.company.freemiumPermissions.hideDataInGraphs || false;
  }
  hasAllowHideDataInGraphsDefault = () => {
    return this.company.freemiumPermissions.hideDataInGraphsDefault || false;
  }

  hasAutoAdjustStartTimeBasedOnEstimation = () => {
    return this.company.freemiumPermissions.autoAdjustStartTimeBasedOnEstimation || false;
  }

  hasQaSignature = () => {
    return this.company.freemiumPermissions.qaSignature || false;
  }

  hasSafetySignature = () => {
    return this.company.freemiumPermissions.safetySignature || false;
  }

  hasAllowSmartAnswering = () => {
    return this.company.freemiumPermissions.allowSmartAnswering || false;
  }

  hasAllowCollaboardativeMaintenance = () => {
    return this.company.freemiumPermissions.allowCollaboardativeMaintenance || false;
  }
  
  hasConnectedEvents = () => {
    return this.company.freemiumPermissions.connectedEvents || false;
  }

  hasUseToolAsWarehouse = () => {
    return this.company.freemiumPermissions.useToolAsWarehouse || false;
  }

  hasMsTeams = () => {
    return this.company.freemiumPermissions.msTeams || false;
  }

  hasGroupPmUpdate = () => {
    return this.company.freemiumPermissions.groupPmUpdate || false;
  }

  hasDrawingModePermission = () => {
    return this.company.freemiumPermissions.drawingMode || false;
  }

  hasPmCalenderPermission = () => {
    return this.company.freemiumPermissions.pmCalender || false;
  }

  getSignatureCriticalText = () => {
    return this.company.signatureCriticalText || null;
  }

  calculateOverDueOnlyTime = (event: ToolEvent): number => {
    if (event.scheduleWork && this.hasSmartSchedulePermission()) {
      const work = new Date(this.convertDateForDateObject(event.scheduleWork))
      const diff = this.checkTwoDates(new Date((moment(work).format('YYYY-MM-DD HH:mm').replace(/-/g, '/'))), new Date(this.convertDateForDateObject()));
      if (diff > 0) {
        return diff;
      }
    }
    return 0;
  }

  calculateOverDueTime = (event: ToolEvent) => {
    const time = this.calculateOverDueOnlyTime(event);
    if (time) {
      return this.getTimeShorterInString(time);
    }
    return '';
  }

  getOverDueTimeTimeClass = (event: ToolEvent) => {
    if (event && event.status === EVENT_STATUS.PENDING_MODERATION) {
      return 'event-pending-moderation';
    } else if (event && event.status === EVENT_STATUS.WAITING_FOR_QA) {
      return 'event-pending-qa';
    } else if (event && event.status === EVENT_STATUS.WAITING_FOR_SAFETY) {
      return 'event-pending-safety';
    } else if (event && typeof event.done != 'undefined' && event.done) {
      return 'event-done-outbound';
    }
    if (event && typeof event.scheduleWork != 'undefined' && event.scheduleWork && this.hasSmartSchedulePermission()) {
      const work = new Date(this.convertDateForDateObject(event.scheduleWork))
      const diff = this.checkTwoDates(new Date(this.convertDateForDateObject()), new Date((moment(work).format('YYYY-MM-DD HH:mm').replace(/-/g, '/'))));
      if (diff < 0) {
        return 'time-outbound'
      }
    }
    return '';
  }

  getMaintenanceClass = (event: ToolEvent) => {
    if (event && event.status === EVENT_STATUS.WAITING_FOR_QA) {
      return 'event-pending-qa';
    } else if (event && event.status === EVENT_STATUS.WAITING_FOR_SAFETY) {
      return 'event-pending-safety';
    }
    return '';
  }

  getInactivityTime = (type = "miliseconds"): number => {
    const m = this.company.autoSignoutAfterMinutes && this.company.autoSignOut ? this.company.autoSignoutAfterMinutes : 0;
    if (type == "miliseconds") {
      return m * 60 * 1000;
    } else if (type == "hours") {
      return Number((m / 60));
    } else {
      return m;
    }
  }

  checkFileExtention = (event) => {
    const validExt = this.auth.getAllowedFileExtentions().split(',');
    const fileExt = event.target.files[0].name.split(".").pop().toLowerCase()

    if (!validExt.includes(fileExt)) {
      this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_INVALID_FILE'))
      return false;
    }

    return true;
  }

  checkImageFileExtention = (event) => {
    const validExt = this.auth.getAllowedImageFileExtentions().split(',');
    const fileExt = event.target.files[0].name.split(".").pop().toLowerCase()

    if (!validExt.includes(fileExt)) {
      this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_INVALID_FILE_IMAGE'))
      return false;
    }

    return true;
  }

  checkDirectFileExtention = (file) => {
    const validExt = this.auth.getAllowedFileExtentions().split(',');
    const fileExt = file.name.split(".").pop().toLowerCase()

    if (!validExt.includes(fileExt)) {
      this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_INVALID_FILE'))
      return false;
    }

    return true;
  }

  checkAiAssistanceFile = (file) => {
    const fileExt = file.name.split(".").pop().toLowerCase()

    if (!['pdf', 'doc', 'docx', 'pptx', 'xlsx', 'csv'].includes(fileExt)) {
      this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_INVALID_AI_FILE'))
      return false;
    }

    return true;
  }

  checkUsername = (name) => {
    if (this.checkInputLength(name)) return false;
    const regex = /^[a-zA-Z0-9-_]+$/;
    return regex.test(name);
  }

  checkInputLength = (value, length = 15) => {
    return value ? value.length > length : false;
  }

  getLightScrollbarOptions = () => {
    return { axis: 'y', theme: 'dark', mouseWheel: { scrollAmount: "auto" } };
  }

  getSlowLightScrollbarOptions = () => {
    return { axis: 'y', theme: 'dark' };
  }

  getTrimmedOptions(values) {
    return isEmpty(values) ? [] : values
  }

  getScrollToTime = (value: string = "", type = "start") => {
    if (value && moment(value).isValid()) {
      return new Date(moment(value).format(this.globalDateTimeFormat))
    } else {
      if (type === "start") {
        return new Date(moment(moment().format(this.globalDateFormat) + " " + this.company.dailyWorkHours.start).format(this.globalDateTimeFormat))
      }
      return new Date(moment(moment().format(this.globalDateFormat) + " " + this.company.dailyWorkHours.end).format(this.globalDateTimeFormat))
    }
  }

  checkGridRowValidation = (params) => {
    const errors = [];

    const { $row_status } = params.data;

    if ($row_status != "new" && $row_status != "modified") {
      return {
        success: true,
        errors: errors
      }
    }

    const requiredColumns = params.columnApi.columnController.columnDefs.filter(c => c.required);

    if (requiredColumns.length > 0) {
      requiredColumns.map(r => {
        if (r.isPmCustom) {
          if (params.data.pmCustomFields && !params.data.pmCustomFields[r.colId]) errors.push(`${r.headerName.replace(/\*/g, "")} ${this.translate.instant("general.IS-REQUIRED")}`)
        }
        if (r.isCustom && !r.isPmCustom) {
          if (params.data.customFields && !params.data.customFields[r.colId]) errors.push(`${r.headerName.replace(/\*/g, "")} ${this.translate.instant("general.IS-REQUIRED")}`)
        }
        if (r.isRegularColumn) {
          if (params.data && !params.data[r.colId]) errors.push(`${r.headerName.replace(/\*/g, "")} ${this.translate.instant("general.IS-REQUIRED")}`)
        }
      })
    }

    return {
      success: errors.length > 0 ? false : true,
      errors: errors
    }
  }

  checkIsNumber(string: any) {
    return /^[0-9]*\.?[0-9]*$/.test(string)
  }

  formatByteSize = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  convertToGB = (bytes: number) => {
    return (bytes / 1073741824);
  }

  checkValidEmail = (email: string) => {
    const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!validEmail.test(email) && !validEmail.test(email.toLowerCase())) {
      return false;
    }
    return true;
  }

  typeA = (event) => {
    return true;
  }

  typeB = (event) => {
    const k = event.charCode;
    return ((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || (k >= 48 && k <= 57) || k == 45 || k == 95);
  }

  typeC = (event) => {
    const k = event.charCode;
    return (k == 46 || (k >= 48 && k <= 57));
  }

  typeD = (event) => {
    const k = event.charCode;
    return (k >= 48 && k <= 57);
  }

  typeE = (event) => {
    const k = event.charCode;
    return (k == 43 || (k >= 48 && k <= 57));
  }

  getHighlightedText = (text: string, search: string) => {
    const strRegExp = new RegExp(search.replace(/[(]/g, "\\(").replace(/[)]/g, "\\)"), 'i');
    return text.replace(strRegExp, '<strong>' + search + '</strong>');
  }

  downloadFile = (base64: string, name: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = base64;
    downloadLink.download = name;
    downloadLink.click();
  }

  getTranslateToLanguageText = () => {
    return `${this.translate.instant('general.TRANSLATE-TO')} ${this.auth.getUserLanguage()}`;
  }

  getMimeType(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        if(base64 && isString(base64)){
          const filePath = base64.split(';base64,')
          const mimeType = this.detectMimeType(filePath[1]);
          if(mimeType){
            resolve(mimeType)
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      };
      reader.readAsDataURL(blob);
    });
  }

  getBase64OfImage(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        if(base64 && isString(base64)){
          const filePath = base64.split(';base64,')
          const mimeType = this.detectMimeType(filePath[1]);
          if(mimeType){
            resolve(`data:${mimeType};base64,${filePath[1]}`)
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      };
      reader.readAsDataURL(blob);
    });
  }

  detectMimeType = (b64: string) => {
    const signatures = {
      JVBERi0: "application/pdf",
      R0lGODdh: "image/gif",
      R0lGODlh: "image/gif",
      iVBORw0KGgo: "image/png",
      "/9j/": "image/jpg"
    };
    for (const s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
    return null;
  }

  convertStringToMinutes = (time: string) => {
    const splitted = time.split(':');
    if(splitted.length === 2)
      return (Number(splitted[0]) * 60) + Number(splitted[1]);
    return 0;
  }

  getFunctions() {
    return [
      {
        id: FUNCTION_TYPE.AVERAGE,
        text: this.translate.instant('measurements.formula.functions.AVERAGE'),
      },
      {
        id: FUNCTION_TYPE.MAX,
        text: this.translate.instant('measurements.formula.functions.MAX'),
      },
      {
        id: FUNCTION_TYPE.MIN,
        text: this.translate.instant('measurements.formula.functions.MIN'),
      },
      {
        id: FUNCTION_TYPE.RANGE,
        text: this.translate.instant('measurements.formula.functions.RANGE'),
      },
      {
        id: FUNCTION_TYPE.SQRT,
        text: this.translate.instant('measurements.formula.functions.SQRT'),
      },
      {
        id: FUNCTION_TYPE.ST_DEV,
        text: this.translate.instant('measurements.formula.functions.ST-DEV'),
      },
      {
        id: FUNCTION_TYPE.ABS,
        text: this.translate.instant('measurements.formula.functions.ABS'),
      }
    ];
  }

  getMeasurementTypes() {
    return [
      {
        id: FORMULA_MEASUREMENT_TYPE.TARGET,
        text: this.translate.instant('measurements.TARGET'),
      },
      {
        id: FORMULA_MEASUREMENT_TYPE.RESULT,
        text: this.translate.instant('measurements.RESULT'),
      },
      {
        id: FORMULA_MEASUREMENT_TYPE.LSL,
        text: this.translate.instant('measurements.LSL'),
      },
      {
        id: FORMULA_MEASUREMENT_TYPE.USL,
        text: this.translate.instant('measurements.USL'),
      },
      {
        id: FORMULA_MEASUREMENT_TYPE.LCL,
        text: this.translate.instant('measurements.LCL'),
      },
      {
        id: FORMULA_MEASUREMENT_TYPE.UCL,
        text: this.translate.instant('measurements.UCL'),
      }
    ];
  }

  getFunctionText(functionType: FUNCTION_TYPE) {
    const functions = this.getFunctions();
    return functions.find((f) => f.id === functionType)?.text;
  }

  getTextFromFormula = (formula: Formula) => {
    if(formula.text) {
      return formula.text;
    } else if (formula.function) {
      return this.getFunctionText(formula.function);
    } else if (formula.measurement) {
      return this.getMeasurementTypes().find((m) => m.id === formula.measurement)?.text;
    }
    return '';
  }

  getLastMonthUnix = () => {
    const timezone = this.getTimezone();
    return {
      lastMonthStartUnix: moment().subtract(1, 'months').startOf('month').tz(timezone).unix(),
      lastMonthEndUnix: moment().subtract(1, 'months').endOf('month').tz(timezone).unix()
    }
  }

  getDateUnix = (date: string | Date | Moment) => {
    return moment(date).tz(this.getTimezone()).unix();
  }

  getBoldText = (text: string) => {
    const bold = /\*\*(.*?)\*\*/gm;
    const html = text.replace(bold, '<strong>$1</strong>');
    return html;
  }
}
