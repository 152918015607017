import { IField } from "../../interfaces/field";
import { taskKind } from "../taskKind";
import { taskType } from "../taskType";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.checklist.table-title.ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'company.name',
        label: 'table.checklist.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'name',
        label: 'table.checklist.table-title.TASK-NAME',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'taskTypeValue',
        label: "table.checklist.table-title.VALUE",
        type: 'textbox',
        required: false,
        readonly: true,
        section: "checklist"
    },
    {
        name: 'description',
        label: 'table.checklist.table-title.DESCRIPTION',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'link',
        label: 'table.checklist.table-title.LINK',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'procedure',
        label: "table.checklist.table-title.PROCEDURE-ID",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'procedureName',
        label: "table.checklist.table-title.PROCEDURE-NAME",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'toolObject.id_num',
        label: "table.checklist.table-title.RELATED-TOOL-ID",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'tool',
        label: "table.checklist.table-title.RELATED-TOOL-NAME",
        type: 'selectdropdown',
        required: false,
        readonly: false
    },
    {
        name: 'groupObject.id_num',
        label: "table.checklist.table-title.RELATED-GROUP-ID",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'group',
        label: "table.checklist.table-title.RELATED-GROUP-NAME",
        type: 'selectdropdown',
        required: false,
        readonly: false
    },
    {
        name: 'openOnItself',
        label: 'table.checklist.table-title.OPEN-ON-ITSELF',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
    },
    {
        name: 'taskKind',
        label: "table.checklist.table-title.TASK-KIND",
        type: 'dropdown',
        options: taskKind,
        required: false,
        readonly: false
    },
    {
        name: 'taskType',
        label: "table.checklist.table-title.TASK-TYPE",
        type: 'dropdown',
        options: taskType,
        required: false,
        readonly: false,
        section: "checklist"
    },
    {
        name: 'must',
        label: "table.checklist.table-title.MUST",
        type: "checkbox",
        required: false,
        readonly: false
    },
    {
        name: 'twoSignature',
        label: 'table.checklist.table-title.REQUIRE-TWO-SIGNATURE',
        type: "checkbox",
        required: false,
        readonly: false,
    },
    {
        name: 'workTime',
        label: "table.checklist.table-title.WORK-TIME",
        type: "numeric",
        required: false,
        readonly: false
    },
    {
        name: 'image',
        label: "table.checklist.table-title.FILE",
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
    }
]

export default {
    FieldDefinition
}